.text-input-highlight-container {
  position: relative; }
  .text-input-highlight-container .text-input-element {
    background: none;
    position: relative;
    z-index: 2; }
  .text-input-highlight-container .text-highlight-element {
    overflow: hidden !important;
    white-space: pre-wrap;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    color: rgba(0, 0, 0, 0);
    z-index: 1; }
  .text-input-highlight-container .text-highlight-tag {
    background-color: #ebebeb55;
    color: transparent;
    padding: 1px 3px;
    margin: -1px -3px;
    overflow-wrap: break-word; }
