/* You can add global styles to this file, and also import other style files */
body,
html {
    height: 100%;
    /* overflow: hidden; */
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

.bg {
    /* background-color: black!important; */
    border-radius: 0px !important;
    margin-left: auto;
    margin-right: auto;
}

.ql-editor {
    line-height: 2 !important;
    max-height: calc(100vh - 325px) !important;
    transition: height 0.5s ease-out
}


.cdk-virtual-scroll-content-wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
}

.text-gray {
    color: gray !important;
    background: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
}

.end {
    background-color: rgba(235, 235, 235, 0.333) !important;
    border-right: solid 1px rgba(100, 100, 100, 0.5) !important;
}

.middle-span {
    border-style: solid hidden;
    border-width: 2px;
    border-color: rgba(100, 100, 100, 0.5);
}

.last-span {
    border-style: solid solid solid hidden;
    border-width: 2px;
    border-color: rgba(100, 100, 100, 0.5);

}

.btn-sm {
    font-size: 12px !important;
    font-weight: 600 !important;

}

.badge {
    border: 0 !important;
}